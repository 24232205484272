<template>
    <div class="how-replenish-total">
        <h1>How do I replenish the total balance of my business account (Deposit)?</h1>
        <p>
            Request an invoice for payment:
        </p>
        <ol>
            <li>Through a support request</li>
            <li>Via an available communication channel from a web service specialist</li>
        </ol>
    </div>
</template>
<script>
export default {
    name: "howToReplenishTotal",
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
        }
    }
}
</script>
<style lang="scss" scoped>
.how-replenish-total{
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h1{
        line-height: normal;
    }

    ol,
    ul {
        list-style-position: inside;
    }
}
</style>